import React from 'react';
import './GrowYourBussion.css';
import userdil from '../../assets/images/user-dil.svg';
import bag from '../../assets/images/badge-check.svg';
import shield from '../../assets/images/shield-check.svg';
import smile from '../../assets/images/smile.svg';
import mobile from '../../assets/images/affinity.png';
import img from '../../assets/images/img-10.png';
import user from '../../assets/images/user.svg';
import bagcheck from '../../assets/images/bag-check.svg';
import earnYou from '../../assets/images/earnYou.svg'
const GrowYourBussion = () => {
	return (
		<div className="GrowYourBussionWrapper">
			<div className="container">
				<div className="GrowYourBussion-wrapper ">
					<div className="top-wrapper flex gap70 justify-center">

						<div className="left self-center">
							<img src={mobile} alt="mobile" />
						</div>
						<div className="right">
							<div className='flex'>
								<img src={user} style={{ height: "75px", width: "75px" }}></img>
								<div>
									<h4 className="ml22">
										You Refer Friends
									</h4>
									<p className='ml22 mt10'>
									Share your referral link with <br/> friends. They get ₹10.
									</p>
									
								</div>

							</div>

							<div className='flex mt20'>
								<img src={bagcheck} style={{ height: "75px", width: "75px" }}></img>
								<div>
									<h4 className="ml22">
										You Refer Friends
									</h4>
									<p className="ml22 mt10">
									Share your referral link with <br/> friends. They get ₹10.
									</p>
								</div>

							</div>

							<div className='flex mt20'>
								<img src={earnYou} style={{ height: "75px", width: "75px" }}></img>
								<div>
									<h4 className="ml22">
										You Refer Friends
									</h4>
									<p className="ml22 mt10">
									Share your referral link with <br/> friends. They get ₹10.
									</p>
								</div>

							</div>
						</div>


					</div>

					<div className="middle-wrapper">
						<div className='middle-wrapper-top flex'>
							<div className="left text-left self-center">
								<h2>Trusted, Safe & Secure</h2>
								<p>End-to-end payment solutions for businesses. Enables acceptance of payments by UPI, Cards, Digital Wallets, Net Banking and 100+ other channels</p>
							</div>
							<div className="right">
								<img src={img} alt="img" />
							</div>
						</div>
						<div className=" card-wrapper center gap-30">
							<div className="box">
								<div className="img center">
									<img src={bag} alt="shield" />
								</div>
								<h2>Fast Bill Pay</h2>
								<p>Pay Fast Be Smart</p>
							</div>
							<div className="box">
								<div className="img center">
									<img src={userdil} alt="user" />
								</div>
								<h2>Trust pay</h2>
								<p>Pay Fast Be Smart</p>
							</div>
							<div className="box">
								<div className="img center">
									<img src={shield} alt="shield" />
								</div>
								<h2>Secure Payments</h2>
								<p>Moving your card details to a much more secured place.</p>
							</div>
							<div className="box box-4">
								<div className="img center">
									<img src={smile} alt="smile" />
								</div>
								<h2>4X7 Support</h2>
								<p>We're here to help. Have a query and need help ? Click here</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GrowYourBussion;
