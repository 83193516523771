import React, { useState, useEffect, useContext } from 'react';
import osstore from '../../assets/images/apple.svg';
import googlestore from '../../assets/images/playstor.svg';
import Input from '../../Components/UI/Input';
import { Wrapper } from './style';
import { useNavigate } from 'react-router-dom';

import ResetPassword from './ResetPassword';
import OTPVerification from './OTPVerification';
import { userAuthRequest } from '../../utils/api';
import { isEmpty } from '../../utils/common';
import { AlertWrapper } from '../UI/styledConstants';
import Loader from '../UI/Loader/Loader';
import { GlobleContaxt } from '../../Context/GlobleContaxt';
import { InputWrapper } from '../UI/Input/style';

const initialState = { password: '', rememberMe: '', roleCode: 'ROLE_RETAILER', username: '' };

export default function Login() {
	const navigate = useNavigate();

	const [formDetails, setFormDetails] = useState(initialState);
	const [loading, setLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [forgetPasswordClicked, setForgetPasswordClicked] = useState(false);
	const [wasOTPSent, setWasOTPSent] = useState(false);

	const [message, setMessage] = useState({});

	const [phoneNumberForPasswordReset, setPhoneNumberForPasswordReset] = useState('');

	const { setModalConfig } = useContext(GlobleContaxt);
	const numberReg = /^[0-9]+$/;
	const changeHandler = (event) => {
		event.preventDefault();
		if (event.target.name === 'username') {
			if (!numberReg.test(event.target.value) && event.target.value) {
				return false;
			}
		}
		setFormDetails({
			...formDetails,
			[event.target.name]: event.target.value,
		});
	};

	const rememberMeHandler = (event) => {
		setFormDetails({
			...formDetails,
			[event.target.name]: event.target.checked,
		});
	};

	const validateInput = () => {
		if (formDetails.username === '' || formDetails.password === '') {
			setMessage({ type: 'error', msg: 'Username And Password are required!', place: 'local' });
			return false;
		}
		if (formDetails.username.length < 10) {
			setMessage({ type: 'error', msg: 'Please enter 10 digit mobile number', place: 'local' });
			return false;
		}
		if (formDetails.password.length < 6) {
			setMessage({ type: 'error', msg: 'Please enter valid password', place: 'local' });
			return false;
		}

		return true;
	};

	const removeData = () => {
		sessionStorage.clear();
	};

	const forgetPasswordHandler = () => {
		setForgetPasswordClicked(true);
		setFormDetails(initialState);
	};

	const onSubmitHandler = async (event) => {
		event.preventDefault();
		const isValid = validateInput();
		if (!isValid) {
			return;
		}

		setMessage({});
		setLoading(true);
		const response = await userAuthRequest(formDetails);
		setLoading(false);
		if (response?.id_token) {
			sessionStorage.setItem('ID Token', response?.id_token);
			sessionStorage.setItem('UserRole', response?.authorities);

			setModalConfig({ status: false, config: null });
			navigate('/dashboard', { replace: true });
		} else {
			removeData();

			if (response?.errorCodeList?.length > 0) {
				setMessage({ type: 'error', msg: response?.errorCodeList[0], place: 'local' });
			} else {
				setMessage({ type: 'error', msg: response?.message || 'Something went wrong Please try again', place: 'local' });
			}
		}
	};

	useEffect(() => {
		if (!isEmpty(message)) {
			setTimeout(() => {
				setMessage({});
			}, 5000);
		}
	}, [message]);

	return (
		<Wrapper>
			<div className="login-wrapper">
				<div className="left">
					<h1>PAY BILLS QUICKLY AND EASILY.</h1>
					<h2>Your Affinity Enterprises  world now in your pocket.</h2>
					<div className="download-app">
						<p>To create an account download Affinity Enterprises  App</p>
						{/* <div className="app-store flex">
							<img className="os-store" src={osstore} alt="" />
							<img className="google-store" src={googlestore} alt="" />
						</div> */}
					</div>
				</div>
				{!forgetPasswordClicked && !wasOTPSent && (
					<div className="right">
						<h3>To Login into your Affinity Enterprises  Web account</h3>
						{!isEmpty(message) && message.place === 'local' && (
							<AlertWrapper className={`${message.type} local`}>
								<span className="close" onClick={() => setMessage({})}>
									×
								</span>
								{message.msg}
							</AlertWrapper>
						)}

						<Input
							elemConfig={{
								type: 'text',
								name: 'username',
								value: formDetails.username,
								maxLength: '10',
							}}
							placeholder={'Mobile No.'}
							onChange={changeHandler}
						/>
						<InputWrapper className="input-container">
							<div className="i-icon" onClick={() => setShowPassword(!showPassword)}>
								{showPassword ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash" aria-hidden="true"></i>}
							</div>
							<input className="input-text" type={showPassword ? 'text' : 'password'} onChange={changeHandler} name="password" value={formDetails.password} maxLength={20} />
							<label className="placeholder-text">
								<span className="text">Password</span>
							</label>
						</InputWrapper>
						{/* <Input 
                            elemConfig={{
                                type: 'password',
                                name: 'password',
                                value: formDetails.password,
                                maxLength:"20",
                                id:"password"
                            }}
                            placeholder = {'Password'}
                            onChange={changeHandler}
                        /> */}

						<div className="forgot-password-link">
							<Input placeholder="Remember me" type="checkbox" name="rememberMe" onChange={rememberMeHandler} />

							<button onClick={forgetPasswordHandler}>Forgot Your Password?</button>
						</div>
						<button className="login-button" onClick={onSubmitHandler}>
							LOGIN
						</button>
						{loading && <Loader />}
					</div>
				)}
				{forgetPasswordClicked && !wasOTPSent && (
					<ResetPassword
						setForgetPasswordClicked={setForgetPasswordClicked}
						onSuccessfullySent={(phoneNumber) => {
							setWasOTPSent(true);
							setPhoneNumberForPasswordReset(phoneNumber);
						}}
					/>
				)}
				{wasOTPSent && <OTPVerification phoneNumber={phoneNumberForPasswordReset} setWasOTPSent={setWasOTPSent} setForgetPasswordClicked={setForgetPasswordClicked} />}
			</div>
		</Wrapper>
	);
}
